.header {
    background: #ffffff none repeat scroll 0 0;
    box-shadow: 0px -2px 4px rgb(32, 32, 32) inset;
}


a {
    color: #000000;
    font-size: 1.2rem;
    font-weight: 600;
    text-decoration: none;
}


.buy {
    background: #ffc221;
    padding: 4px 25px !important;
    margin-left: 29px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buy:hover {
    color: #fff;
}

/* .main-menu ul>li {
    position: inherit;
    display: inline-block;
    vertical-align: middle;
    margin: 10px 20px;
}

.nav>li {
    position: inherit;
    display: inline-block;
    vertical-align: middle;
} */

.logo {
    height: 3.4em;
    display: flex;
    align-items: center;
    margin: 8px 20px;
}

.nav-bar {
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: right;
}

.main-menu-text{
    margin-right: 1.5em;
}

.main-menu .main-menu-text:hover,
.main-menu .main-menu-text:focus {
    color: #ff2121;
    border-bottom: #fe0000 solid 3px;
}

.main-menu .active {
    color: #fe0000;
    border-bottom: #fe0000 solid 3px;
}

/* nav.main-menu {
    float: right;
    margin-left: 0;

} */

@media (max-width: 768px){
    .main-menu-text{
        margin-right: 1em;
        font-size: 1em;
    }
}

@media (max-width: 576px){
    .main-menu-text{
        margin-right: 1em;
        font-size: 0.9em;
    }

    .logo {
        height: 2.8em;
        display: flex;
        align-items: center;
        margin: 8px 4px 8px 5px;
    }    
}

@media (max-width: 475px){
    .main-menu-text{
        margin-right: 0.8em;
        font-size: 0.8em;
        font-weight: 500;
    }
    .logo {
        height: 2.3em;
        display: flex;
        align-items: center;
        margin: 8px 5px 8px 4px;
    }    
}

/* Responsive Adjustments */
/* @media (max-width: 768px) {
    .logo {
        width: 15%;
    }

    .logo img{
        height: 65px;
        width: 60px;
    }
} */