/* AddStaff Component Styles */
.add-staff-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.add-staff-container h2 {
    text-align: center;
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 20px;
}

.add-staff-container form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.add-staff-container label {
    font-weight: 600;
    color: #555;
    margin-bottom: 5px;
}

.add-staff-container input[type="text"],
.add-staff-container input[type="email"],
.add-staff-container input[type="password"] {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.add-staff-container input[type="checkbox"] {
    margin-right: 10px;
}

.add-staff-container h3 {
    font-size: 1.4rem;
    margin-top: 10px;
    color: #333;
    text-align: left;
}

.add-staff-container .permissions-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.add-staff-container .permissions-container label {
    flex: 1 1 48%;
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.add-staff-container button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 600;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

.add-staff-container button:hover {
    background-color: #0056b3;
}

.add-staff-container button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.radios {
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
}