.invoice-container {
  /* max-width: 800px;
  min-width: 800px; */
  background: white;
  border: 1px solid #000000;
  color: black;
}

.invoice-outer {
  background-color: #ffffff;
  margin: 0 auto;
  padding: 20px 30px;
}

.registrations {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 600;
  padding: 0px 6px;
}

.right-foot p {
  font-size: 12px;
  font-weight: 600;
  padding: 0px 6px;
  margin: 0;
}

.invoice-total p {
  font-size: 14px;
  font-weight: 600;
  padding: 0px 6px;
  margin: 0;
}

.right-foot {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 0;
  align-items: center;
}

.footer-data {
  min-height: 140px;
  padding: 0 5px;
  display: grid;
  grid-template-columns: 75% 25%;
}

.registrations p {
  margin: 2px 0px;
}

.client-details p {
  margin: 1px 0px;
}

.client-details {
  display: flex;
  justify-content: space-between;
  padding: 0px 6px;
  font-size: 14px;
  font-weight: 500;
}

.invoice-header {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  /* color: rgb(99, 215, 170); */
}

.invoice-header p {
  margin: 0;
  font-weight: 600;
}

.invoice-header h2 {
  margin: 0;
  font-size: 22px;
  font-weight: bold;
}

.address-line {
  text-align: start;
  margin: 0px 5px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

.invoice-table th,
.invoice-table td {
  padding: 5px;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  border-right: 1px solid #000000;
  text-align: left;
}

.invoice-table th {
  background-color: #e9e9e9;
  font-weight: 600;
}

.invoice-total {
  height: 100px;
  font-size: 14px;
  text-align: right;
  font-weight: 600;
}

.total-details {
  display: flex;
  justify-content: space-between;
  padding: 0px 5px;
  border-bottom: 1px solid #000000;
}

.invoice-footer {
  text-align: left;
  font-size: 14px;
}

.print-btn {
  background-color: #4cacaf;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 200px;
  font-weight: 700;
}

.print-btn:hover {
  background-color: #297f82;
}

@media print {
  .print-btn {
    display: none;
    /* Hide the print button in the printed version */
  }

  .invoice-header h2 {
    font-size: 20px;
  }

  .right-foot {
    text-align: center;
  }

  .invoice-table th,
  .invoice-table td {
    font-size: 14px;
  }
}