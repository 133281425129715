.page_404 {
    background: #fff;
    font-family: 'Arvo', serif;
}

.page_404 img {
    width: 100%;
}

.four_zero_four_bg {
    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 75vh;
    background-position: center;
}

.my-flexer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.four_zero_four_bg h1 {
    font-size: 80px;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.contant_box_404 {
    margin-top: -90px;
    margin-bottom: 55px;
}