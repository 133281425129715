.advance-bookings-section {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    margin: 0 auto;
}

.advance-bookings-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.date-picker-adv {
    font-weight: 700;
    color: black;
}

h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.advance-bookings-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.advance-bookings-table th,
.advance-bookings-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
    text-align: center;
}

.advance-bookings-table th {
    background-color: #9438c5;
    color: white;
}

.advance-bookings-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.checkin-btn {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 8px 10px;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 500;
}

.checkin-btn:hover {
    background-color: #45a049;
}

.add-booking-btn {
    display: block;
    background-color: #007bff;
    border: none;
    padding: 10px 16px;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
    margin: 0 auto;
    text-decoration: none;
}

.add-booking-btn a {
    color: white;
    font-size: 1rem;
    font-weight: 600;
}

.add-booking-btn:hover {
    background-color: #0056b3;
}