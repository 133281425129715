/* General Banner Section */
.banner_main {
    position: relative;
}

/* Booking Section */
.booking_ocline {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9999;
}

.book_room {
    background-color: #000000c7;
    padding: 20px 35px;
    border-left: #ff0909 solid 5px;
}

option {
    color: black;
    font-size: 18px;
}

.book_room h1 {
    font-weight: 500;
    font-size: 30px;
    line-height: 30px;
    color: #fff;
    padding-bottom: 20px;
    text-transform: uppercase;
    text-align: center;
}

.book_now span {
    display: block;
    color: #fff;
    font-size: 18px;
    padding-bottom: 5px;
}

.book_now .online_book {
    width: 100%;
    height: 50px;
    border: #fff solid 1px;
    background-color: transparent;
    color: #fff;
    padding: 0 10px;
    padding-right: 61px;
    margin-bottom: 15px;
    position: relative;
}

.date_cua {
    position: absolute;
    width: 29px;
    height: 29px;
    top: 48px;
    right: 30px;
    background-repeat: no-repeat;
}

.book_btn {
    background-color: #fe0000;
    max-width: 189px;
    width: 100%;
    margin: 0 auto;
    padding: 10px 0;
    border-radius: 50px;
    color: #fff;
    font-size: 20px;
    transition: ease-in 0.5s;
    display: block;
    border: none;
}

.book_btn:hover {
    font-weight: 600;
    background-color: #fff;
    color: #fe0000;
    transition: ease-in 0.5s;
}

/* React Bootstrap Carousel */
.carousel img {
    height: 100vh;
    object-fit: cover;
}

.carousel .carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #000;
}

.carousel .carousel-indicators .active {
    background-color: #fe0000;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
}

.carousel-control-prev-icon:hover,
.carousel-control-next-icon:hover {
    background-color: #fe0000;
}

.carousel-control-prev,
.carousel-control-next {
    width: 5%;
}

.book_now span {
    display: block;
    color: #fff;
    font-size: 18px;
    padding-bottom: 5px;
}

.carousel-control-next,
.carousel-control-prev {
    display: none;
}

.carousel-indicators {
    right: inherit;
    bottom: 53px;
    margin-left: 9%;
}

.floating-contact {
    position: fixed;
    z-index: 1000;
    width: 50px;
    height: 50px;
    color: white;
    padding-top: 4px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: all 0.3s ease;
}

.floating-text {
    position: fixed;
    z-index: 1000;
    bottom: 90px;
    right: 20px;
    color: white;
}

.floating-contact a {
    color: white;
    text-decoration: none;
}

.floating-contact i {
    font-size: 25px;
}

.floating-contact.whatsapp {
    bottom: 30px;
    right: 100px;
    background-color: #25d366;
}

.floating-contact.call {
    bottom: 30px;
    right: 20px;
    background-color: #007bff;
}

.floating-contact:hover {
    transform: scale(1.1);
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.4);
}

/* Responsive Adjustments */
@media (max-width: 768px) {

    .book_room h1 {
        font-size: 24px;
    }

    .book_now .online_book {
        height: 40px;
    }

    .book_btn {
        font-size: 18px;
    }

    .carousel-indicators {
        bottom: 0px;
    }
}