/* Guest Modal Styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease-out;
    position: relative;
    margin-top: 180px;
    margin-bottom: 50px;
}

.modal-content h2 {
    margin-top: 0;
    font-size: 24px;
    color: #333;
}

.modal-content label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #555;
}

.modal-content input[type="text"],
.modal-content input[type="file"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.modal-content button {
    display: inline-block;
    padding: 10px 15px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-right: 10px;
}

.modal-content button:hover {
    background-color: #0056b3;
}

/* Close button */
.modal-content button:last-child {
    background-color: #6c757d;
}

.modal-content button:last-child:hover {
    background-color: #5a6268;
}

.suggestions-list {
    position: fixed;
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 2px solid #089a90;
    border-radius: 5px;
    max-height: 150px;
    overflow-y: auto;
    top: 210px;
}

.suggestion-item {
    display: grid;
    width: 455px;
    padding: 8px;
    cursor: pointer;
    background-color: white;
}

.suggestion-item:hover {
    background-color: #f0f0f0;
}

.toggle-container {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: Arial, sans-serif;
}

/* Switch styling */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
}

.switch input {
    display: none;
    /* Hide the default checkbox */
}

/* Slider styling */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 25px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 3.5px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

/* Checked state */
input:checked+.slider {
    background-color: #4caf50;
}

input:checked+.slider:before {
    transform: translateX(24px);
}

/* Fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}