.book-room-form {
    max-width: 600px;
    margin: 3rem auto;
    background: #f4f4f4;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease-in-out;
}

.book-room-form h2 {
    text-align: center;
    margin-bottom: 20px;
}

.book-room-form input,
.book-room-form textarea,
.book-room-form select {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.book-room-form select {
    border-right: 10px solid transparent;
}

.book-room-form button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #28a78c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
}

.book-room-form button:hover {
    background-color: #178970;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}