.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modals {
    background: #fff;
    padding: 20px;
    position: relative;
    width: 90vw;
    max-height: 100vh;
    overflow-y: auto; /* For scrollable content */
    border-radius: 10px;
    animation: fadeIn 0.3s ease-in-out; /* Adding fade-in animation */
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 2rem;
    color: #333;
    cursor: pointer;
}

.food-list {
    max-height: 60vh;
    overflow-y: auto; /* Scroll food list if needed */
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
}

.total-section {
    display: flex;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 8px;
    justify-content: space-around;
    align-items: center;
}

.total-section p {
    margin: 0 15px; /* Adds space between elements */
    font-size: 1.1rem;
    font-weight: 700;
    color: #000000;
}

.book-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    font-weight: 600;
}

.book-button:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .modals {
        width: 95%;
    }
}

.modals label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
}

.modals input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

/* Adjusting row-wise layout for totals section */
.total-section {
    flex-wrap: wrap; /* Ensures wrapping on smaller screens */
}

@media (max-width: 768px) {
    .total-section {
        flex-direction: column; /* Stack items vertically on mobile */
    }
}
