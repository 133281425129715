.reports-page {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

.filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 20px 10px;
    background-color: rgb(192, 201, 255);
    border-radius: 10px;
}

.filter-item {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    overflow: hidden;
}

.date-picker {
    display: flex;
    align-items: center;
}

.date-picker span {
    margin: 0 10px;
}

input,
select {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 8px;
    width: 100%;
}

select{
    border-right: 4px solid transparent;
}

.fetch-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    align-self: center;
}

.fetch-btn:hover {
    background-color: #0056b3;
}

.reports-table {
    width: 100%;
    border-collapse: collapse;
}

.reports-table th,
.reports-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
}

i {
    font-size: 20px;
}

.reports-table th {
    background-color: #007bff;
    color: white;
}