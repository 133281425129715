.nft {
    user-select: none;
    margin: 1.8rem;
    margin-bottom: 0em;
    border: 1px solid #ffffff22;
    background-color: #282c34;
    background: linear-gradient(0deg, rgb(36, 39, 46) 0%, rgba(17, 0, 32, .5) 100%);
    box-shadow: 0 7px 20px 5px #00000088;
    border-radius: .7rem;
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    overflow: hidden;
    transition: .5s all;

    h2{
        color: #ffffff;
    }

    hr {
        width: 100%;
        border: none;
        border-bottom: 1px solid #88888855;
        margin-top: 0;
        margin-bottom: 0.5rem;
    }

    ins {
        text-decoration: none;
    }

    .main {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 1rem;

        .tokenImage {
            border-radius: .5rem;
            width: 50%;
            margin: auto;
        }

        .description {
            color: #a89ec9;
            margin-bottom: 0.3rem;
        }

        .tokenInfo {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .price {
                display: flex;
                align-items: center;
                color: #ee83e5;
                font-weight: 700;

                ins {
                    margin-left: -.3rem;
                    margin-right: .5rem;
                }
            }

            .duration {
                display: flex;
                align-items: center;
                color: #a89ec9;
                margin-right: .2rem;

                ins {
                    margin: .5rem;
                    margin-bottom: .4rem;
                }
            }
        }

        .creator {
            display: flex;
            align-items: center;
            margin-top: .2rem;
            margin-bottom: -.3rem;

            ins {
                color: #a89ec9;
                text-decoration: none;
            }

            .wrapper {
                display: flex;
                align-items: center;
                border: 1px solid #ffffff22;
                padding: .3rem;
                margin: 0;
                /* margin-right: .5rem; */
                border-radius: 100%;
                box-shadow: inset 0 0 0 4px #000000aa;

                img {
                    border-radius: 100%;
                    border: 1px solid #ffffff22;
                    width: 2rem;
                    height: 2rem;
                    object-fit: cover;
                    margin: 0;
                }
            }
        }
    }

    ::before {
        position: fixed;
        content: "";
        box-shadow: 0 0 100px 40px #ffffff08;
        top: -10%;
        left: -100%;
        transform: rotate(-45deg);
        height: 60rem;
        transition: .7s all;
    }

    &:hover {
        border: 1px solid #ffffff44;
        box-shadow: 0 7px 50px 10px #000000aa;
        transform: scale(1.015);
        filter: brightness(1.3);

        ::before {
            filter: brightness(.5);
            top: -100%;
            left: 200%;
        }
    }

    .card-btn {
        background-color: #0c0c0caa;        
        border: none;
        padding: 10px;
        font-size: 18px;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .card-btn a{
        color: white;
    }
}