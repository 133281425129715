/* Styling for the Add Rooms form */
.add-room-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1s ease-in;
}

@keyframes fadeIn {
    0% { opacity: 0; transform: translateY(-20px); }
    100% { opacity: 1; transform: translateY(0); }
}

.add-room-form {
    display: flex;
    flex-direction: column;
}

.form-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
}

.form-group input, .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

textarea {
    resize: vertical;
    height: 100px;
}

.submit-btn-room {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px;
    font-size: 18px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.submit-btn-room:hover {
    background-color: #45a049;
}

/* Responsive Design */
@media (max-width: 768px) {
    .add-room-container {
        padding: 10px;
    }

    .form-title {
        font-size: 20px;
    }

    .submit-btn-room {
        font-size: 16px;
    }
}
