@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');

* {
	font-family: 'Poppins';
}

html {
	scroll-behavior: smooth;
}

html, body{
	overflow-x: clip;
}

body {
	background-color: #282c34;
	color: #666666;
	font-family: 'poppins', sans-serif;
	font-weight: normal;
	/* overflow-x: hidden; */
}

